import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

const About = ({isDarkMode}: {isDarkMode: boolean}) => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});

  const toggleSection = (section: string) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const savedLanguage = localStorage.getItem('language');

  const elements = [
    {
      id: 'section1',
      title: savedLanguage === 'en' ? 'IT Support and Services' : savedLanguage === 'ro' ? 'Asistență și Servicii IT' : 'Поддержка и услуги ИТ',
      text: savedLanguage === 'en' ? 
        'At ThinkIT, we provide top-notch IT support and services tailored to meet the unique needs of businesses. Our IT maintenance solutions ensure seamless operations, while our IT services drive growth by delivering efficient and reliable support.' : 
        savedLanguage === 'ro' ? 
        'La ThinkIT, oferim asistență și servicii IT de top, adaptate pentru a satisface nevoile unice ale companiilor. Soluțiile noastre de întreținere IT asigură operațiuni fără probleme, în timp ce serviciile IT oferă suport eficient și fiabil pentru creștere.' : 
        'В ThinkIT мы предоставляем первоклассную ИТ-поддержку и услуги, адаптированные для удовлетворения уникальных потребностей бизнеса. Наши решения по обслуживанию ИТ обеспечивают бесперебойную работу, а ИТ-услуги способствуют росту, предоставляя эффективную и надежную поддержку.'
    },
    {
      id: 'section2',
      title: savedLanguage === 'en' ? 'IT Maintenance and Optimization' : savedLanguage === 'ro' ? 'Întreținere și Optimizare IT' : 'Обслуживание и оптимизация ИТ',
      text: savedLanguage === 'en' ? 
        'ThinkIT offers comprehensive IT maintenance services, ensuring your infrastructure is always up-to-date and operating efficiently. From server optimization to network security, we provide solutions that reduce downtime and enhance productivity.' :
        savedLanguage === 'ro' ? 
        'ThinkIT oferă servicii complete de întreținere IT, asigurându-se că infrastructura dvs. este întotdeauna actualizată și funcționează eficient. De la optimizarea serverelor la securitatea rețelelor, oferim soluții care reduc timpul de inactivitate și sporesc productivitatea.' :
        'ThinkIT предлагает комплексные услуги по обслуживанию ИТ, гарантируя, что ваша инфраструктура всегда обновлена и работает эффективно. От оптимизации серверов до обеспечения безопасности сети — мы предлагаем решения, которые сокращают время простоя и повышают производительность.'
    },
    {
      id: 'section3',
      title: savedLanguage === 'en' ? 'Client Success Stories' : savedLanguage === 'ro' ? 'Povești de Succes ale Clienților' : 'Истории успеха клиентов',
      subSections: [
        {
          count: '20',
          subText: savedLanguage === 'en' ? 'IT Support Projects Completed' : savedLanguage === 'ro' ? 'Proiecte de Asistență IT Finalizate' : 'Завершено проектов ИТ-поддержки'
        },
        {
          count: '3000',
          subText: savedLanguage === 'en' ? 'Clients Benefited from IT Services' : savedLanguage === 'ro' ? 'Clienți care au Beneficiat de Servicii IT' : 'Клиенты, получившие ИТ-услуги'
        },
        {
          count: '6',
          subText: savedLanguage === 'en' ? 'Trusted IT Partners' : savedLanguage === 'ro' ? 'Parteneri IT de Încredere' : 'Надежные ИТ-партнеры'
        }
      ]
    }
  ];

  return (
    <section className={`about ${isDarkMode ? '' : 'light'}`}>
      <div className="about-left">
        <div className="about-left__info">
          <h1 className="about-title">
            {savedLanguage === 'en' ? 'About Our IT Support' : savedLanguage === 'ro' ? 'Despre Asistența IT' : 'О нашей ИТ-поддержке'}
          </h1>
          <p className="about-text">
            {
              savedLanguage === 'ro' ? 'ThinkIT se specializează în întreținerea și optimizarea infrastructurii IT pentru clienți, oferind servicii IT fiabile și asistență continuă. Soluțiile noastre de suport IT permit companiilor să își continue activitatea fără întreruperi, beneficiind de cele mai noi tehnologii.' : 
              savedLanguage === 'en' ? 'ThinkIT specializes in maintaining and optimizing IT infrastructure for clients, providing reliable IT services and continuous support. Our IT support solutions enable businesses to run smoothly without interruptions, benefiting from the latest technologies.' : 
              'ThinkIT специализируется на обслуживании и оптимизации ИТ-инфраструктуры для клиентов, предоставляя надежные ИТ-услуги и постоянную поддержку. Наши решения по ИТ-поддержке позволяют бизнесу работать без сбоев, пользуясь самыми современными технологиями.'
            }
          </p>
        </div>
        <div className="about-left__elements">
          {elements.map((section) => (
            <article
              key={section.id}
              className={`about-element ${openSections[section.id] ? 'active' : ''}`}
            >
              <div className="about-element__title">
                <h3>{section.title}</h3>
                <button onClick={() => toggleSection(section.id)}>
                  {openSections[section.id] ? '-' : '+'}
                </button>
              </div>
              { section.text ? (
                <p className="about-element__text">{section.text}</p>
              ) : (
                <article className='about-element__article'>
                  {section.subSections?.map(subSection => (
                    <div className='about-element__div' key={nanoid()}>
                      <h3>{subSection.count}+</h3>
                      <p>{subSection.subText}</p>
                    </div>
                  ))}
                </article>
              )}
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
