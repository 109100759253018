import { logo1, logo2 } from '../images';
import { scrollToBottom } from '../utils/utils';
import Services from './Services';
import PartnerShip from './PartnerShip';
import About from './About';
import WhyUs from './WhyUs';
import {bg, lightBg} from '../images/index'

interface Props {
  isDarkMode: boolean
}

const Header = ({ isDarkMode }: Props) => {
  const savedLanguage = localStorage.getItem('language')
  
  return (
    <main className={`header ${isDarkMode ? '' : 'light'}`}>
      <div 
      className={`header-intro ${isDarkMode ? '' : 'light'}`}
      style={{
        backgroundImage: `url(${isDarkMode ? bg : lightBg})`,
        backgroundSize: "114vw",
        backgroundPosition: "center"
      }}
      > 
       <div className="hero-introduction">
       <h1 className='header-title'>{
        savedLanguage === 'en' ?
        "IT Support and Maintenance Services" : 
        savedLanguage === 'ru' ? 'Услуги ИТ-поддержки и обслуживания' :
        "Servicii de suport și întreținere IT"
        }</h1>
        <p className='hero-introduction__text'>
        {savedLanguage === 'en' ? 'ThinkIT is a company that provides services for the maintenance of the IT infrastructure of our clients. Do you need computers, servers, networks in your company to work flawlessly - contact us!' :
        savedLanguage === 'ro' ? 'ThinkIT este o companie care oferă servicii de întreținere a infrastructurii IT a clienților noștri. Aveți nevoie de computere, servere, rețele în compania dvs. pentru a funcționa impecabil - contactați-ne!' : 
        'ThinkIT — компания, предоставляющая услуги по обслуживанию ИТ-инфраструктуры наших клиентов. Вам нужно, чтобы компьютеры, серверы, сети в вашей компании работали безупречно - обратитесь к нам!'}
        </p>
        <div className="header-buttons">
        <button 
          className={`btn ${isDarkMode ? '' : 'light'}`} 
          onClick={() => {
            scrollToBottom()
          }}>
          {savedLanguage === 'en' ?
          "Contact Us" : 
          savedLanguage === 'ru' ? 'Свяжитесь с нами' :
          "Contactaţi-ne"
        }</button>
        <a
          href="#services"
          className={`header-buttons__services btn ${isDarkMode ? '' : 'light'}`}>
          {savedLanguage === 'en' ?
          "Our Services" : 
          savedLanguage === 'ru' ? 'Наши Услуги' :
          "Serviciile Noastre"
        }</a>
        </div>
      </div>
      <img src={isDarkMode ? logo2 : logo1} alt="Header Image" className='hero-intro__img'/>

    </div>
      <About isDarkMode={isDarkMode}/>
      <Services isDarkMode={isDarkMode}/>
      <WhyUs isDarkMode={isDarkMode}/>
      <PartnerShip isDarkMode={isDarkMode}/>
    </main>
  );
};
export default Header;