import { FaInfinity, FaLightbulb, FaShieldAlt } from "react-icons/fa";

const WhyUs = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const savedLanguage = localStorage.getItem("language");

  return (
    <div className={`why ${isDarkMode ? "" : "light"}`}>
      <div className="why-upper">
        <h1 className="why-upper__title">
          {savedLanguage === "en"
            ? "Why Choose Us for IT Support?"
            : savedLanguage === "ro"
            ? "De ce să ne alegeți pentru suport IT?"
            : "Почему выбрать нас для ИТ-поддержки?"}
        </h1>
        <p className="why-upper__text">
          {savedLanguage === "en"
            ? "Discover how our comprehensive IT services, including IT support, IT maintenance, and IT solutions, elevate your business. We excel in delivering secure, reliable, and innovative technology solutions tailored to your needs."
            : savedLanguage === "ro"
            ? "Descoperiți cum serviciile noastre IT, inclusiv suportul IT, întreținerea IT și soluțiile IT, vă îmbunătățesc afacerea. Ne remarcăm prin furnizarea de soluții tehnologice sigure, fiabile și inovatoare, adaptate nevoilor dumneavoastră."
            : "Узнайте, как наши ИТ-услуги, включая ИТ-поддержку, ИТ-обслуживание и ИТ-решения, улучшают ваш бизнес. Мы специализируемся на предоставлении безопасных, надежных и инновационных технологий, адаптированных к вашим потребностям."}
        </p>
      </div>
      <div className="why-lower">
        <div className="why-articles">
          <article className="why-article">
            <FaInfinity className="why-article__icon" />
            <div className="why-article__info">
              <h3>
                {savedLanguage === "en"
                  ? "Unlimited Potential"
                  : savedLanguage === "ro"
                  ? "Potencial Nelimitat"
                  : "Неограниченный Потенциал"}
              </h3>
              <p>
                {savedLanguage === "en"
                  ? "Our IT services push the boundaries of what's possible, offering IT support and IT maintenance that continuously evolve to meet the demands of your business."
                  : savedLanguage === "ro"
                  ? "Serviciile noastre IT împing limitele a ceea ce este posibil, oferind suport IT și întreținere IT care evoluează constant pentru a satisface cerințele afacerii dvs."
                  : "Наши ИТ-услуги расширяют границы возможного, предоставляя ИТ-поддержку и ИТ-обслуживание, которые постоянно развиваются для удовлетворения потребностей вашего бизнеса."}
              </p>
            </div>
          </article>
          <article className="why-article">
            <FaLightbulb className="why-article__icon" />
            <div className="why-article__info">
              <h3>
                {savedLanguage === "en"
                  ? "Innovative Ideas"
                  : savedLanguage === "ro"
                  ? "Idei Inovatoare"
                  : "Инновационные Идеи"}
              </h3>
              <p>
                {savedLanguage === "en"
                  ? "We bring cutting-edge IT solutions and creative strategies to keep your IT infrastructure and services ahead of the curve."
                  : savedLanguage === "ro"
                  ? "Aducem soluții IT de ultimă generație și strategii creative pentru a menține infrastructura și serviciile IT înaintea curbei."
                  : "Мы предлагаем передовые ИТ-решения и креативные стратегии, чтобы ваша ИТ-инфраструктура и услуги опережали конкурентов."}
              </p>
            </div>
          </article>
          <article className="why-article">
            <FaShieldAlt className="why-article__icon" />
            <div className="why-article__info">
              <h3>
                {savedLanguage === "en"
                  ? "Reliable Security"
                  : savedLanguage === "ro"
                  ? "Securitate Fiabilă"
                  : "Надежная Безопасность"}
              </h3>
              <p>
                {savedLanguage === "en"
                  ? "We prioritize IT security in all our services, ensuring that your IT systems, data, and networks are protected with the most advanced measures."
                  : savedLanguage === "ro"
                  ? "Prioritizăm securitatea IT în toate serviciile noastre, asigurându-ne că sistemele, datele și rețelele dvs. IT sunt protejate cu cele mai avansate măsuri."
                  : "Мы ставим ИТ-безопасность на первое место во всех наших услугах, обеспечивая защиту ваших ИТ-систем, данных и сетей с помощью самых современных мер."}
              </p>
            </div>
          </article>
        </div>
        <h2 className="why-lower__title">
          {savedLanguage === "en"
            ? "Boost Your Business with Expert IT Services"
            : savedLanguage === "ro"
            ? "Îmbunătățiți-vă afacerea cu servicii IT de expertiză"
            : "Улучшите свой бизнес с помощью экспертных ИТ-услуг"}
        </h2>
      </div>
    </div>
  );
};

export default WhyUs;
